export default {
  allReviewsProviders: [],
  totalReviewsProviders: {
    totalItems: 5,
    totalPages: 5,
  },

  allReviews: [],
  totalReviews: {
    totalItems: null,
    totalPages: null,
    rateAverage: null,
    rates: null,
    provider: null
  },
};
