export default {
  async loadDashboardContent({ commit }) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('admin/dashboard')
      .then(response => {
        if (response.status === 200) {
          commit('setDashboardContent', response.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

};
