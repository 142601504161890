export default {

  async loadSubscriptionsPackages({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`/admin/subscriptions?page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setSubscriptionPackages', respo.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadSubscriptionsPackageDetail({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/subscriptions/${data.id}?order_by=${data.order_by}&search=${data.search}&status=${data.status}&type=${data.type}&page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setUsers', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  // todo :: remove this function loadSubscriptionDetails

  async loadSubscriptionDetails({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('provider/subscription')
      .then(respo => {
        if (respo.status === 200) {
          commit('setSubscriptionDetails', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
};
