import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import dashboard from './dashboard';
import auth from './auth';
import packages from './packages';
import newsletter from './newsletter';
import financial from './financial';
import reviews from './reviews';
import sections from './sections';
import coupons from './coupons';
import skills from './skills';
import users from './users';
import applicants from './applicants';
import works from './works';
import services from './services';
import utilities from './utilities';
import app from './app';
import chats from './chats';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import halabik from './halabik';
import settings from './settings';
import profile from './profile';
import booking from './booking';
import subscription from './subscription';
import currencies from './currencies';
import categories from './categories';
import interests from './interests';
import destinations from './destinations';
import ads from './ads';
import articles from './articles';
import requests from './requests';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    app,
    appConfig,
    verticalMenu,
    auth,
    coupons,
    users,
    newsletter,
    financial,
    reviews,
    sections,
    applicants,
    services,
    works,
    skills,
    utilities,
    chats,
    packages,
    halabik,
    settings,
    profile,
    booking,
    currencies,
    subscription,
    categories,
    interests,
    destinations,
    ads,
    articles,
    requests,
  },
  strict: process.env.DEV,
});
