export default {
  allBookings: null,
  bookingDetails: {
    totalItems: 5,
    totalPages: 5,
  },
  totalBookings: {
    totalItems: 5,
    totalPages: 5,
  },
};
