export default {
  getAllArticles(state) {
    return state.allArticles;
  },
  getTotalArticles(state) {
    return state.totalArticles;
  },
  getArticleDetails(state) {
    return state.ArticleDetails;
  },
};
