// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdoqRCaN2fWJrEPFdRCNMVbyUtZGS7BO8",
  authDomain: "halabkapp.firebaseapp.com",
  projectId: "halabkapp",
  storageBucket: "halabkapp.appspot.com",
  messagingSenderId: "868131246347",
  appId: "1:868131246347:web:d6b222c7a0a9b56661d20e",
  measurementId: "G-K3Z4M92KEM"
};

// Initialize Firebase
initializeApp(firebaseConfig);
