// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async loadDestinations({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/destinations?order_by=${data.order_by}&search=${data.search}&status=${data.status}&page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDestinations', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addDestination({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('admin/destinations/create', data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'destinations', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Destination_added_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateDestination({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post(`admin/destinations/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'destinations', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Destination_updated_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadDestinationDetails({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/destinations/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setDestinationDetails', respo.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

};
