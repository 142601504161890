export default {
  setCategories: (state, data) => {
    state.allCategories = data.content;
    state.totalCategories = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setCategoryDetails: (state, data) => {
    state.CategoryDetails = data.data;
  },
};
