export default {
  setRequests: (state, data) => {
    state.allRequests = data.content;
    state.totalRequests = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setRequestDetails: (state, data) => {
    state.RequestDetails = data;
  },
};
