export default {
  setAds: (state, data) => {
    state.allAds = data.content;
    state.totalAds = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },

};
