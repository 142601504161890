export default {
  getAllUsers(state) {
    return state.allUsers;
  },

  getTotalUsers(state) {
    return state.totalUsers;
  },

  getUserDetail(state) {
    return state.userDetail;
  }
};
