export default {
  allUsers: [],
  totalUsers: {
    totalItems: 5,
    totalPages: 5,
  },

  userDetail: {},

};
