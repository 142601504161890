export default {
  setSubscriptionDetails: (state, data) => {
    state.subscriptionDetails = data;
  },

  setSubscriptionPackages: (state, data) => {
    state.allSubscriptionPackages = data.data.content;
    state.totalSubscriptionPackages = {
      totalItems: data.data.total,
      totalPages: data.data.last_page,
      restrictions: data.restrictions,
    };
  },
  setSubscriptionPackageDetails: (state, data) => {
    state.subscriptionPackageDetails = data;
  },
};
