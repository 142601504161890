import i18n from '@/libs/i18n';
import middleware from '../middleware';

export default [

  {
    path: 'login',
    name: 'login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'login-bg',
    },
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: '',
    },
  },
  {
    path: 'reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'login-bg',
    },
  },
  {
    path: 'validate-email',
    name: 'validate-email',
    component: () => import('@/views/pages/authentication/ValidateEmail.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'login-bg',
    },
  },
  {
    path: 'verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/authentication/VerifyEmail.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: '',
      noBackButton: true,
    },
  },
  {
    path: '',
    name: 'home',
    redirect: `/${i18n.locale}/dashboard`,
  },
  {
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/dashboard.vue'),
    meta: {
      middleware: [middleware],
    },
  },

  {
    path: 'users',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/pages/users/users.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'tourists',
        name: 'users-tourists',
        component: () => import('@/views/pages/users/tourists/tourists.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'tourists/:id',
        name: 'users-tourists-details',
        component: () => import('@/views/pages/users/tourists/tourist-details.vue'),
        meta: {
          middleware: [middleware],
        },
      },

      {
        path: 'providers',
        name: 'users-providers',
        component: () => import('@/views/pages/users/providers/providers.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'providers/:id',
        name: 'users-providers-details',
        component: () => import('@/views/pages/users/providers/provider-details.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },

  {
    path: 'articles',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'articles',
        component: () => import('@/views/pages/articles/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },

      {
        path: '',
        name: 'addArticle',
        component: () => import('@/views/pages/articles/create.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':id',
        name: 'articleDetail',
        component: () => import('@/views/pages/articles/view.vue'),
        meta: {
          middleware: [middleware],
        },
      },

      {
        path: ':id/edit',
        name: 'updateArticle',
        component: () => import('@/views/pages/articles/update.vue'),
        meta: {
          middleware: [middleware],
        },
      },

      // {
      //   path: 'subscription',
      //   name: 'subscription',
      //   component: () => import('@/views/pages/settings/Subscription.vue'),
      //   meta: {
      //     middleware: [middleware],
      //   },
      // },
    ],
  },

  {
    path: 'notification',
    name: 'notification',
    component: () => import('@/views/pages/dashboard/notification.vue'),
    meta: {
      middleware: [middleware],
    },
  },

  {
    path: 'subscriptions',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'subscriptions',
        component: () => import('@/views/pages/subscriptions/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },

      {
        path: ':id',
        name: 'subscriptionDetail',
        component: () => import('@/views/pages/subscriptions/detail.vue'),
        meta: {
          middleware: [middleware],
        },
      },

    ],
  },

  {
    path: 'chats',
    name: 'chats',
    component: () => import('@/views/pages/chats/index.vue'),
    meta: {
      middleware: [middleware],
    },
    props: true,
  },
  {
    path: 'packages',
    component: {
      render(c) {
        return c('router-view');
      },
    },

    children: [
      {
        path: '',
        name: 'packages',
        component: () => import('@/views/pages/packages/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'add-package',
        name: 'addPackage',
        component: () => import('@/views/pages/packages/AddPackage.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':id',
        name: 'package-details',
        component: () => import('@/views/pages/packages/details.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },
  {
    path: 'bookings',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'bookings',
        component: () => import('@/views/pages/bookings/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':id',
        name: 'booking-details',
        component: () => import('@/views/pages/bookings/details.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },
  {
    path: 'reviews',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'reviews',
        component: () => import('@/views/pages/reviews/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':id',
        name: 'reviewDetail',
        component: () => import('@/views/pages/reviews/detail.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },
  {
    path: 'newsletters',
    name: 'newsletters',
    component: () => import('@/views/pages/newsletter/index.vue'),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: 'financial-operations',
    name: 'financial-operations',
    component: () => import('@/views/pages/financial/index.vue'),
    meta: {
      middleware: [middleware],
    },
  },
  {
    path: 'financial-operations/:id',
    name: 'financial-operations-detail',
    component: () => import('@/views/pages/financial/detail.vue'),
    meta: {
      middleware: [middleware],
    },
  },

  {
    path: 'coupons',
    component: {
      render(c) {
        return c('router-view');
      },
    },

    children: [
      {
        path: '',
        name: 'coupons',
        component: () => import('@/views/pages/coupons/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'add-coupon',
        name: 'addCoupon',
        component: () => import('@/views/pages/coupons/AddCoupon.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':id',
        name: 'coupon-details',
        component: () => import('@/views/pages/coupons/details.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },

     
  {
    path: 'settings',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('@/views/pages/settings/settings.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'advance',
        name: 'advance',
        component: () => import('@/views/pages/settings/advance/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'currencies',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'currencies',
            component: () => import('@/views/pages/settings/currencies/index.vue'),
            meta: {
              middleware: [middleware],
            },
          },
          {
            path: 'create',
            name: 'addCurrency',
            component: () => import('@/views/pages/settings/currencies/create.vue'),
            meta: {
              middleware: [middleware],
            },
          },
          {
            path: ':id',
            name: 'updateCurrency',
            component: () => import('@/views/pages/settings/currencies/update.vue'),
            meta: {
              middleware: [middleware],
            },
          },
        ],
      },

      {
        path: 'destinations',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'destinations',
            component: () => import('@/views/pages/settings/destinations/index.vue'),
            meta: {
              middleware: [middleware],
            },
          },
          {
            path: 'create',
            name: 'addDestination',
            component: () => import('@/views/pages/settings/destinations/create.vue'),
            meta: {
              middleware: [middleware],
            },
          },
          {
            path: ':id',
            name: 'updateDestination',
            component: () => import('@/views/pages/settings/destinations/update.vue'),
            meta: {
              middleware: [middleware],
            },
          },
        ],
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import('@/views/pages/settings/categories/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'active-providers',
        name: 'active-providers',
        component: () => import('@/views/pages/settings/active-providers/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'interests',
        name: 'interests',
        component: () => import('@/views/pages/settings/interests/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'ads',
        name: 'ads',
        component: () => import('@/views/pages/settings/ads/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import('@/views/pages/settings/Subscription.vue'),
        meta: {
          middleware: [middleware],
        },
      },
    ],
  },

  {
    path: 'requests',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'requests',
        component: () => import('@/views/pages/requests/requests.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'active',
        name: 'providerRequestServices',
        component: () => import('@/views/pages/requests/providers/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: 'active/details/:id',
        name: 'requestProviderDetail',
        component: () => import('@/views/pages/requests/providers/detail.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':type',
        name: 'featureRequestServices',
        component: () => import('@/views/pages/requests/services/index.vue'),
        meta: {
          middleware: [middleware],
        },
      },
      {
        path: ':type/details/:id',
        name: 'featureRequestServiceDetail',
        component: () => import('@/views/pages/packages/details.vue'),
        meta: {
          middleware: [middleware],
        },
      },

    ],
  },
];
