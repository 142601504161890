export default {
  subscriptionDetails: null,
  allSubscriptionPackages: null,
  totalSubscriptionPackages: {
    totalItems: 5,
    totalPages: 5,
  },

  subscriptionPackageDetails: {
    totalItems: 5,
    totalPages: 5,
  },
};
