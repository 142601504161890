// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async loadCategories({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/categories?page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setCategories', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addCategory({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('admin/categories/create', data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'categories', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Category_added_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateCategory({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post(`admin/categories/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'categories', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Category_updated_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },


};
