export default {
  getAllRequests(state) {
    return state.allRequests;
  },
  getTotalRequests(state) {
    return state.totalRequests;
  },
  getRequestDetails(state) {
    return state.RequestDetails;
  },
};
