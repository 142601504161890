export default {
    getAllFinancial(state) {
        return state.allFinancial;
    },
    getTotalFinancial(state) {
        return state.totalFinancial;
    },

    getFinancialDetail(state) {
        return state.financialDetail;
    }
};
