// import axios from '@/plugins/axios';
export default {
  async loadFinancial({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `admin/financials?order_by=${data.order_by}&search=${data.search}&year=${data.year}&month=${data.month}&period=${data.period}&transaction_type=${data.transaction_type}&page=${data.page}&per_page=${data.per_page}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setFinancial', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadFinancialDetail({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `admin/financials/${id}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setFinancialDetail', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
