export default {
  getSubscriptionDetails(state) {
    return state.subscriptionDetails;
  },


  getAllSubscriptionPackages(state) {
    return state.allSubscriptionPackages;
  },
  getTotalSubscriptionPackages(state) {
    return state.totalSubscriptionPackages;
  },
  getSubscriptionPackageDetails(state) {
    return state.subscriptionPackageDetails;
  },
};
