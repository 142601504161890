export default {
  setRequestsCounts: (state, data) => {
    state.requestsCounts = data;
    state.requestsSum = data['active'] + data['cancel'] + data['feature'];
  },

  //todo:: chech these
  setSubscriptions: (state, data) => {
    state.subscriptions = data;
  },
  setAllPackageDropdown: (state, data) => {
    state.allPackageDropdown = data;
  },
  setAllInterests: (state, data) => {
    state.allInterests = data;
  },
  setAllCountries: (state, data) => {
    state.allCountries = data;
  },
  setAllSaudiCities: (state, data) => {
    state.allSaudiCities = data;
  },
  setAllAccountTypes: (state, data) => {
    state.allAccountTypes = data;
  },
  setAllNotifications: (state, data) => {
    state.notifications = data;
  },
};
