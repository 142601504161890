export default {
  getAllCategories(state) {
    return state.allCategories;
  },
  getTotalCategories(state) {
    return state.totalCategories;
  },
  getCategoryDetails(state) {
    return state.CategoryDetails;
  },
};
