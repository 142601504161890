// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
    async loadArticles({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/articles?order_by=${data.order_by}&search=${data.search}&page=${data.page}&per_page=${data.per_page}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setArticles', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },
    async addArticle({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .post('admin/articles/create', data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'articles', params: {lang: i18n.locale}});
                    commit('loadingFinish');
                    commit('successMessage', 'Article_added_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async updateArticle({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/articles/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'articles', params: {lang: i18n.locale}});
                    commit('loadingFinish');
                    commit('successMessage', 'Article_updated_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async loadArticleDetails({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/articles/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setArticleDetails', respo.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async deleteArticle({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .delete(`admin/articles/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'articles', params: {lang: i18n.locale}});
                    commit('loadingFinish');
                    commit('successMessage', 'Article_deleted_successfully');

                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

};
