export default {
  getAllReviews(state) {
    return state.allReviews;
  },
  getTotalReviews(state) {
    return state.totalReviews;
  },

  getAllReviewsProviders(state) {
    return state.allReviewsProviders;
  },

  getTotalReviewsProviders(state) {
    return state.totalReviewsProviders;
  },
};
