export default {
  setArticles: (state, data) => {
    state.allArticles = data.content;
    state.totalArticles = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setArticleDetails: (state, data) => {
    state.ArticleDetails = data.data;
  },
};
