export default {
  getAllDestinations(state) {
    return state.allDestinations;
  },
  getTotalDestinations(state) {
    return state.totalDestinations;
  },
  getDestinationDetails(state) {
    return state.DestinationDetails;
  },
};
