export default {
    getAllWorks(state) {
      return state.allWorks;
    },
    getTotalWorks(state) {
      return   state.totalWorks;
    },
    getWorkDetails(state) {
      return state.workDetails;
    },
};
