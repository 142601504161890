import router from '@/router';

export default {
  async loadPackages({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `admin/provider-services?page=${data.page}&per_page=${data.per_page}&status=${data.status}&city=${data.city}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setPackages', respo.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadProviderPackages({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `admin/provider-services/provider/${data.id}?page=${data.page}&per_page=${data.per_page}&status=${data.status}&city=${data.city}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setPackages', respo.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadPackagesDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/provider-services/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setPackageDetails', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
