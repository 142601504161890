import router from "@/router";
import i18n from "@/libs/i18n";

export default {
    async loadTourists({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/users/tourists?order_by=${data.order_by}&search=${data.search}&status=${data.status}&page=${data.page}&per_page=${data.per_page}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setUsers', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async loadTouristDetail({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/users/tourists/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setUserDetail', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async toggleTourist({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/users/tourists/${id}/toggle`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish');
                    if (respo.data.data === false) {
                        commit('successMessage', 'Tourist_activated_successfully');
                    } else {
                        commit('successMessage', 'Tourist_stopped_successfully');
                    }
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },



    async toggleProvider({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/users/providers/${id}/toggle`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish');
                    if (respo.data.data === false) {
                        commit('successMessage', 'Provider_activated_successfully');
                    } else {
                        commit('successMessage', 'Provider_stopped_successfully');
                    }
                }
            })
            .catch(error => {

                commit('loadingFinish');
                if (error.response.status === 406) {
                    commit('failMessage', 'provider_is_rejected');
                }
                throw error;
            });
    },

    async loadProviders({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/users/providers?order_by=${data.order_by}&search=${data.search}&status=${data.status}&rate=${data.rate}&subscription=${data.subscription}&type=${data.type}&page=${data.page}&per_page=${data.per_page}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setUsers', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async loadProviderDetail({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/users/providers/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setUserDetail', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

};
