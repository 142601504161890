// import axios from '@/plugins/axios';
export default {
  async loadNewsletter({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `admin/newsletters?order_by=${data.order_by}&search=${data.search}&year=${data.year}&month=${data.month}&page=${data.page}&per_page=${data.per_page}`,
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setNewsletter', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

};
