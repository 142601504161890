export default {
  setUsers: (state, data) => {
    state.allUsers = data.content;
    state.totalUsers = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },

  setUserDetail: (state, data) => {
    state.userDetail = data;
  }
};
