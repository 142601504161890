// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async loadInterests({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/interests?page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setInterests', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addInterest({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('admin/interests/create', data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'interests', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Interest_added_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateInterest({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post(`admin/interests/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'interests', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Interest_updated_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

};
