// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async loadCurrencies({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/currencies?order_by=${data.order_by}&search=${data.search}&status=${data.status}&page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setCurrencies', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addCurrency({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('admin/currencies/create', data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'currencies', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Currency_added_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateCurrency({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post(`admin/currencies/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'currencies', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Currency_updated_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async deleteCurrency({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .delete(`admin/currencies/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'currencies', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Currency_deleted_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async toggleCurrency({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .post(`admin/currencies/${id}/toggle`)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'currencies', params: { lang: i18n.locale } });
          commit('loadingFinish');
          if (respo.data.data === 1) {
            commit('successMessage', 'Currency_activated_successfully');
          } else {
            commit('successMessage', 'Currency_stopped_successfully');
          }
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadCurrencyDetails({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/currencies/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setCurrencyDetails', respo.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

};
