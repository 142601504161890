export default {
  async loadSettingsInfo({ commit }) {
    commit('loadingStart', null);
    return await this._vm.$http
      .get(`admin/settings`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSettingsInfo', respo.data.data);

          commit('loadingFinish');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateTax({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post(`admin/settings/tax`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          commit('successMessage', 'tax_changed');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async adminActiveProvider({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post(`admin/operations/active-provider`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          commit('successMessage', 'provider_active');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async updateContacts({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post(`admin/settings/contacts`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          commit('successMessage', 'contacts_changed');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
