export default {
  allPackages: null,
  packageDetails: {
    totalItems: 5,
    totalPages: 5,
  },
  totalPackages: {
    totalItems: 5,
    totalPages: 5,
  },
};
