export default {
  getAllCurrencies(state) {
    return state.allCurrencies;
  },
  getTotalCurrencies(state) {
    return state.totalCurrencies;
  },
  getCurrencyDetails(state) {
    return state.CurrencyDetails;
  },
};
