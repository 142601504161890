export default {
  getAllInterests(state) {
    return state.allInterests;
  },
  getTotalInterests(state) {
    return state.totalInterests;
  },
  getInterestDetails(state) {
    return state.InterestDetails;
  },
};
