// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
    async loadServicesRequests({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/requests/services/${data.type}?order_by=${data.order_by}&search=${data.search}&city=${data.city}&page=${data.page}&per_page=${data.per_page}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setRequests', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async loadProvidersRequests({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/requests/providers?order_by=${data.order_by}&search=${data.search}&city=${data.city}&type=${data.type}&page=${data.page}&per_page=${data.per_page}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setRequests', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async loadProviderRequestDetail({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .get(`admin/requests/providers/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setRequestDetails', respo.data.data);

                    commit('loadingFinish');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async approveProviderRequest({commit}, id) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/requests/providers/${id}/approve`)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'providerRequestServices', params: {lang: i18n.locale}});
                    commit('loadingFinish');
                    commit('successMessage', 'Request_approved_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async rejectProviderRequest({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/requests/providers/${data.id}/reject`,data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'providerRequestServices', params: {lang: i18n.locale}});
                    commit('loadingFinish');
                    commit('successMessage', 'Request_rejected_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async rejectServiceRequest({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/requests/services/${data.type}/${data.id}/reject`,data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'featureRequestServices', params: {lang: i18n.locale,type:data.type}});
                    commit('loadingFinish');
                    commit('successMessage', 'Request_rejected_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },

    async approveServiceRequest({commit}, data) {
        commit('loadingStart', null);
        await this._vm.$http
            .post(`admin/requests/services/${data.type}/${data.id}/approve`,data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'featureRequestServices', params: {lang: i18n.locale,type:data.type}});
                    commit('loadingFinish');
                    commit('successMessage', 'Request_approved_successfully');
                }
            })
            .catch(error => {
                commit('loadingFinish');
                throw error;
            });
    },


};
