// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async loadAds({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`admin/ads?page=${data.page}&per_page=${data.per_page}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setAds', respo.data.data);

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addAd({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('admin/ads/create', data)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'ads', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Ad_added_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async deleteAd({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .delete(`admin/ads/${id}`)
      .then(respo => {
        if (respo.status === 200) {
          router.push({ name: 'ads', params: { lang: i18n.locale } });
          commit('loadingFinish');
          commit('successMessage', 'Ad_deleted_successfully');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

};
