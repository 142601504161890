export default {
  setCurrencies: (state, data) => {
    state.allCurrencies = data.content;
    state.totalCurrencies = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setCurrencyDetails: (state, data) => {
    state.CurrencyDetails = data.data;
  },
};
