export default {

  getRequestsCounts(state) {
    return state.requestsCounts;
  },
  getRequestsSum(state) {
    return state.requestsSum;
  },

  //todo:: chech these
  getSubscriptions(state) {
    return state.subscriptions;
  },
  getAllPackageDropdown(state) {
    return state.allPackageDropdown;
  },

  getAllCountries(state) {
    return state.allCountries;
  },
  getAllSaudiCities(state) {
    return state.allSaudiCities;
  },
  getAllAccountTypes(state) {
    return state.allAccountTypes;
  },
  getAllNotifications(state) {
    return state.notifications;
  },
};
