// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";
export default {
  async loadWorks({ commit }, data) {
    commit("loadingStart");
    await this._vm.$http
      .get(
        `works?page=${data.page}&per_page=${data.per_page}&entity_type=${data.entity_type}&budget=${data.budget}&services=${data.services}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setWorks", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadWorkDetails({ commit }, workId) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`works/${workId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setWorkDetails", respo.data.data.content);

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
