export default {
  setInterests: (state, data) => {
    state.allInterests = data.content;
    state.totalInterests = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setInterestDetails: (state, data) => {
    state.InterestDetails = data.data;
  },
};
