export default {
  setDestinations: (state, data) => {
    state.allDestinations = data.content;
    state.totalDestinations = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setDestinationDetails: (state, data) => {
    state.DestinationDetails = data.data;
  },
};
