export default {
  setFinancial: (state, data) => {
    state.allFinancial = data.content;
    state.totalFinancial = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setFinancialDetail: (state, data) => {
    state.financialDetail = data;

  },
};
